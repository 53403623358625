import { render, staticRenderFns } from "./qbdetails.vue?vue&type=template&id=1b00ef8c&scoped=true"
import script from "./qbdetails.vue?vue&type=script&lang=js"
export * from "./qbdetails.vue?vue&type=script&lang=js"
import style0 from "./qbdetails.vue?vue&type=style&index=0&id=1b00ef8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b00ef8c",
  null
  
)

export default component.exports